import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Per Mossos d'Esquadra, Guàrdia Urbana i Policies Locals</strong>
                </p>
                <p>
                    <strong>Horari:</strong> Dilluns a divendres de 17:00h a 20:00h.
                </p>
                <p>
                    <strong>Localització:</strong> BADAMEDIC
                </p>
                <p>Carrer de Baldomer Solà, 80, 08912 Badalona, Barcelona</p>
                <p>
                    <strong>Telèfon:</strong> 933 87 80 59
                </p>
                <p>
                    <strong>Només amb cita prèvia!</strong>
                </p>

                <p>
                    Durant la revisió es comprovaran els valors màxims o mínims susceptibles a
                    l’exclusió mèdica segons les següents proves:
                </p>

                <ul>
                    <li>
                        <strong>Tensió arterial:</strong> TA superior a 145 mmHg o TA diastòlica
                        superior a 90 mmHg. També hipotensió simptomàtica.
                    </li>
                    <li>
                        <strong>Audiometria:</strong> Valors iguals o superiors a 45dB a 4.000 Hz en
                        una o les dues oïdes. També iguals o superiors a 35dB en freqüències de 500
                        a 3.000 Hz.
                    </li>
                    <li>
                        <strong>Agudesa visual:</strong> Llunyana inferior al 40% (4/10) en un o
                        ambdós ulls sense correcció (ulleres o lents de contacte).
                    </li>
                    <li>
                        <strong>Escoliosi:</strong> Valoració de l'angle de COBB superior a 15
                        graus.
                    </li>
                    <li>
                        <strong>IMC:</strong> Valors entre 18,5 i 30 Kg/m².
                    </li>
                    <li>
                        <strong>Espirometria:</strong> Capacitat vital (FVC) inferior al 80% del
                        valor teòric, FEV1 inferior al 80% del valor teòric o FEV1/FVC inferior al
                        70%.
                    </li>
                    <li>
                        <strong>Discromatòpsies:</strong> Prova per identificar el daltonisme.
                        Resultats validats segons el test de Farnsworth indicat a les bases.
                    </li>
                </ul>

                <p>
                    <strong>Perquè a iOpos ens importa la teva salut!</strong>
                </p>

                <div class="rounded p-5 border shadow-md mt-5">
                    <embed
                        src="@static/documents/products/163/document3.pdf"
                        type="application/pdf"
                        width="100%"
                        height="750px"
                    ></embed>
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Supera els requisits mèdics per les teves oposicions amb la revisió més completa i professional, adaptada a les bases oficials!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";


const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>

            <div className="container lg:px-0 py-20">
                <Title>El Curs</Title>
                <Text>
                    Per Mossos d' Esquadra, Guàrdia Urbana i Policies locals
                    <br />
                    <br />
                    Dilluns a divendres de 17:00h a 20:00h.
                    <br />
                    <br />
                    BADAMEDIC
                    <br />
                    <br />
                    Carrer de Baldomer Solà, 80, 08912 Badalona, Barcelona
                    <br />
                    <br />
                    Telèfon: 933 87 80 59
                    <br />
                    <br />
                    Només cita prèvia!
                    <br />
                    <br />
                    En aquesta revisió és revisaran els valors màxims o mínims susceptibles a
                    l’exclusió mèdica de les següents proves:
                    <br />
                    <br />
                    1- Tensió arterial en repòs de TA superior a 145 mmHg i mesura de TA distòlica
                    superior a 90 mmHg. Hipotensió simptomàtica.
                    <br />
                    <br />
                    2- Valors d' audiometria mesurada sense correcció auditiva amb xifres iguaks o
                    superiors a 45dB a la freqüència de 4.000 Hz en una o en les dues oïdes.Valors
                    d' audiometria mesurada sense correcció auditiva, amb xifres iguals o superiors
                    a 35db a les freqüències compreses entre 500 i 3.000 Hz en una o en les dues
                    oïdes
                    <br />
                    <br />
                    3- Agudesa visual llunyana inferior el 40% (4/10) en un o els dos ulls mesurada
                    sense correcció ( ulleres o lents de contacte)
                    <br />
                    <br />
                    4- Avaluació de l' escoliosi a 15 graus (angle de COBB)
                    <br />
                    <br />
                    5- IMC barems entre 18,5 i 30 Kg/m2
                    <br />
                    <br />
                    6- Espirometria per avaluar a la capacitat vital (FVC)inferior al 80% del seu
                    valor teòric FEV1 inferior al 80% del seu valor teòric o el FEV1/FVC inferior al
                    70%
                    <br />
                    <br />
                    7- Prova de Discromatòpsies -daltonisme. Revisió de factors de Daltonisme amb el
                    resultat positiu o negatiu a Discromatòpsia.Els resultats d' apte o no apte
                    hauran de ser validats segons el test de Farnworth indicat a les bases
                    <br />
                    <br />
                    Perquè a iOpos ens importa la teva salut!
                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/163/document3.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;*/
